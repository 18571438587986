import ApiClient from "../../ApiClient";

// FOR GETTING Inbox status
class QrRequest extends ApiClient {
  constructor() {
    super("channels/unofficial_whatsapp_channel/connection_status", {
      accountScoped: true,
    });
  }

  checkConnection({ instance_name }) {
    const options = {
      method: "GET",
      url: this.url,
    };
    if (instance_name) {
      options.params = { instance_name };
    }
    return axios(options);
  }
}

export default new QrRequest();
