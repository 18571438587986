<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="showNotification" class="ui-notification-container">
      <div class="ui-notification">
        <div class="text-section">
          <p class="ui-notification-text">
            {{ $t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.INBOX_CONNECTION.NOTIFICATION') }}
          </p>
          <button
          @click="reloadPage"
            class="px-2 py-2 underline cursor-pointer font-medium"
          >
          {{$t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.INBOX_CONNECTION.TRY_AGAIN')}}
          </button>
          <span>{{$t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.INBOX_CONNECTION.OR')}}</span>
          <button
            class="px-2 py-2 underline cursor-pointer font-medium"
            @click="reConnectInbox"
          >
          {{$t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.INBOX_CONNECTION.RE_GENETARE_QR')}}
          </button>
          <woot-button
          v-if="uiFlags.isCreating"
          variant="smooth"
          size="tiny"
          color-scheme="secondary"
          :isLoading="uiFlags.isCreating"
          />
        </div>
        <div class="btn-section">
          <woot-button
              variant="smooth"
              size="tiny"
              color-scheme="warning"
              icon="dismiss-circle"
              @click="onCloseToastMessage"
              />
        </div>
        </div>
    </div>
  </transition>
</template>

<script>
import globalConfigMixin from "shared/mixins/globalConfigMixin";
import { mapGetters } from "vuex";
import debounceMixin from "shared/mixins/debounceMixin";
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';

export default {
  mixins: [globalConfigMixin, debounceMixin, alertMixin, inboxMixin],

  data() {
    return {
      showNotification: false,
    };
  },

  computed: {
    ...mapGetters({
      globalConfig: "globalConfig/get",
      activeInbox: "getSelectedInbox",
      uiFlags: "inboxes/getUIFlags"
    }),
    inbox() {
      return this.$store.getters["inboxes/getInbox"](this.activeInbox);
    },
  },

  mounted() {
    bus.$on("newInboxToastMessage", this.onNewToastMessage);
    bus.$on("onCloseInboxToastMessage", this.onCloseToastMessage);
  },
  beforeDestroy() {
    bus.$off("newInboxToastMessage", this.onNewToastMessage);
    bus.$off("onCloseInboxToastMessage", this.onCloseToastMessage);
  },
  methods: {
    onNewToastMessage() {
      this.showNotification = true;
    },
    onCloseToastMessage() {
      this.showNotification = false;
    },
    reloadPage() {
    location.reload();
    },
    async reConnectInbox() {
      this.debouncedHandler(this.reConnectInboxCallback);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~dashboard/assets/scss/mixins";

.ui-notification-container {
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-index-much-higher);
}

.ui-notification {
  @apply bg-red-500 dark:bg-red-700 text-white dark:text-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  box-shadow: var(--shadow-large);
  padding: var(--space-small);
}
.text-section {
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;

}
.btn-section {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;

}
.ui-notification-text {
  margin: 0 var(--space-small);
}
.edit-btn {
@apply px-2 py-2 underline cursor-pointer text-base text-white dark:text-white font-bold;
}
</style>
