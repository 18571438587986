import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      interval:{
        annual: 'price_per_annum',
        monthly: 'price_per_month'
      }
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      subscription: "subscription/getSubscription",
      activeSubscription: "subscription/getActiveSubscription",
    }),
  },
  methods: {
    addAccountScoping(url) {
      return `/app/accounts/${this.accountId}/${url}`;
    },
    
    async initializeAccountBillingSubscription() {
      try {
        await this.$store.dispatch("subscription/getBillingSubscription");
      } catch (error) {
        console.log(error);
      }
    },
    isUpgrade(product) {
      // will be true for greater or equal to current plan
      return (parseInt(product.plan_rank) < parseInt(this.activeSubscription.plan_rank));
    },

    isDowngrade(product) {
      // will be true for less or equal to current plan
      return (parseInt(product.plan_rank) > parseInt(this.activeSubscription.plan_rank));
    },
    getFormatedName(name){
      return name.toLowerCase()
    },
    getPlans(type) {
      if (
        this.getFormatedName(this.subscription.plan_name) === "free" ||
        this.subscription.plan_name === "expired" ||
        this.subscription.subscription_cancellation_status ||
        type === 'localBankTransfer'
      ) {
        // show all plans
        return this.subscription.available_product_prices;
      }

      return this.subscription.available_product_prices.filter((product) => {
        if (
          (type === "upgrade" && this.isUpgrade(product)) ||
          (type === "downgrade" && this.isDowngrade(product))
        ) {
          return product;
        }
      });
    },
    async submitSubscription() {
      try {
        const payload = {
          product_price: this.selectedPlanId,
          subscription_type: this.selectedDuration,
          type: this.type,
          immediate: this.immediate,
        };
        const { url = "" } = await this.$store.dispatch(
          "subscription/submitSubscription",
          payload
        );
        if (url) {
          window.location.href = url;
        } else {
          this.initializeAccountBillingSubscription();
          if(this.showConfirmationPopup) this.showConfirmationPopup = false;
          const notifications = {
            upgrade: this.$t("BILLING_SETTINGS.BILLING_ITEMS.UPGRADE.API.SUCCESS_MESSAGE"),
            downgrade: this.$t("BILLING_SETTINGS.BILLING_ITEMS.DOWNGRADE.API.SUCCESS_MESSAGE"),
            scheduled: this.$t("BILLING_SETTINGS.BILLING_ITEMS.SCHEDULED_PLAN.API.SUCCESS_MESSAGE"), 
          }
          this.showAlert(
            notifications[!this.immediate ? 'scheduled' : this.type]
          );
        }
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        } else {
          this.showAlert(this.$t("BILLING_SETTINGS.FORM.API.ERROR_MESSAGE"));
        }
      }
    },
  },
};
