<template>
  <form class="rounded-md" @submit.prevent="confirm">
    <div class="max-w-sm mx-auto">
      <h2 class="text-2xl font-semibold my-6">{{ formTitle }}</h2>
      <div class="my-12">
        <FormSelect
          label="Please select duration"
          :options="$t('BILLING_SETTINGS.DURATION')"
          id="selectDuration"
          :validationError="$v.selectedDuration.$error"
          :errorMessage="
            $v.selectedDuration.$error ? 'Please select a duration.' : ''
          "
          v-model="selectedDuration"
          :selectedDuration="selectedDuration"
          @validate="validate('selectedDuration')"
        />
        <FormSelect
          label="Please select a plan"
          :options="getPlans(type)"
          id="selectPlan"
          :validationError="$v.selectedPlanId.$error"
          :errorMessage="
            $v.selectedPlanId.$error ? 'Please select a plan.' : ''
          "
          v-model="selectedPlanId"
          :selectedDuration="selectedDuration"
          @validate="validate('selectedPlan')"
        />

        <div v-if="isImmediateAllow" class="flex flex-row content-center">
          <input
            type="checkbox"
            id="checkboxLabel"
            v-model="immediate"
            class="mr-2"
          />
          <label :for="checkboxLabel" class="text-sm font-medium text-gray-700"
            >{{ checkboxLabel }}
          </label>
          <span
            @mouseover="handelTooltip(true)"
            @mouseleave="handelTooltip(false)"
          >
            <fluent-icon icon="info" size="14" class="h-5 w-5 text-red-700" />
          </span>
        </div>
        <div v-show="showTooltip" class="tooltip-container">
          <div class="tooltip-close">
            <woot-button
              variant="smooth"
              size="tiny"
              color-scheme="alert"
              icon="dismiss-circle"
              @click.prevent="handelTooltip(false)"
            />
          </div>
          <p>
            {{
              $t(
                "BILLING_SETTINGS.BILLING_ITEMS.UPGRADE.IMMEDIATE_UPGRADE_TOOLTIP"
              )
            }}
          </p>
        </div>
      </div>
      <div class="my-6">
        <woot-submit-button
          button-text="confirm"
          :loading="uiFlags.isUpdating"
          :disabled="uiFlags.isUpdating"
          class="w-full justify-center"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FormSelect from "./FormSelect.vue";
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import debounceMixin from "shared/mixins/debounceMixin";
import accountMixin from "../../../../../mixins/account";

export default {
  mixins: [alertMixin, debounceMixin, accountMixin],
  components: {
    FormSelect,
  },
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    checkboxLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedDuration: "",
      selectedPlanId: "",
      immediate: false,
      showTooltip: false,
    };
  },
  validations: {
    selectedDuration: { required },
    selectedPlanId: { required },
  },
  computed: {
    ...mapGetters({
      subscription: "subscription/getSubscription",
      activeSubscription: "subscription/getActiveSubscription",
      selectedPlan: "subscription/getSelectedSubscription",
      uiFlags: "subscription/getUIFlags",
    }),
    isImmediateAllow() {
      return (
        this.checkboxLabel &&
        this.getFormatedName(this.subscription.plan_name) !== "free" &&
        this.subscription.plan_name !== "expired"
      );
    },
  },
  methods: {
    async confirm() {
      this.debouncedHandler(this.confirmationCallback);
    },
    async confirmationCallback() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }
        const alreadySubscribed = await this.checkIfAlreadySubscribed();
        if (alreadySubscribed) {
          this.showAlert("You are already using the selected plan.");
          return;
        }
        const alreadyScheduled = await this.checkIfAlreadyScheduled();
        if (alreadyScheduled) {
          this.showAlert("You already scheduled the selected plan.");
          return;
        }
        const intervalChanged = await this.checkIfIntervalChanged();
        if (intervalChanged) {
          this.$emit(
            "openSubscriptionConfirmationPopup",
            this.immediate,
            this.selectedDuration,
            this.selectedPlanId
          );
          return;
        }

        await this.submitSubscription();
        this.$emit("closePopup");
      } catch (error) {
        console.log("error on confirm in UpDowngradeForm", error);
      }
    },
    async checkIfAlreadySubscribed() {
      if (!!this.subscription?.subscription_cancellation_status) {
        return false;
      }
      return (
        this.activeSubscription.id === this.selectedPlanId &&
        this.subscription.plan_interval === this.selectedDuration
      );
    },
    async checkIfAlreadyScheduled() {
      let selectedPlan = this.selectedPlan(this.selectedPlanId);
      return (
        selectedPlan.plan === this.subscription.scheduled_plan &&
        this.subscription.scheduled_interval === this.selectedDuration &&
        !this.immediate
      );
    },
    validate(fieldName) {
      this.$v[fieldName]?.$touch();
      if (this.$v.$pending) {
        return;
      }
      if (this.$v.$invalid) {
        return;
      }
    },
    handelTooltip(value) {
      this.showTooltip = value;
    },
    checkIfIntervalChanged() {
      if (this.immediate) {
        return true;
      } else {
        const hasScheduledSubscription =
          !this.immediate &&
          !this.subscription.subscription_cancellation_status &&
          this.subscription.scheduled_interval;
        if (hasScheduledSubscription) {
          return this.subscription.scheduled_interval !== this.selectedDuration;
        } else {
          return (
            this.subscription.plan_interval &&
            this.subscription.plan_interval !== this.selectedDuration
          );
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tooltip-container {
  @apply absolute mt-2 p-4 shadow-md font-bold text-red-500 dark:text-red-700 bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50;
  z-index: var(--z-index-highest);
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  min-width: 80%;
}
.tooltip-close {
  @apply w-full flex justify-end;
}
</style>
