<template>
  <div class="container mx-auto">
    <div
      class="flex flex-col w-full px-0 mx-auto lg:flex-row"
      v-if="allowCreation"
    >
      <manual-billing-form @backToDetails="allowCreation = false" />
      <bank-details />
    </div>
    <div v-if="!allowCreation">
      <billing-item
        :title="getTitle"
        type="create"
        :description="getDescription"
        :actionButtonText="getButtonText"
        @createLocalTransfer="allowCreation = true"
      />
      <billing-item
        v-if="isSubscriptionSchedualed"
        :title="getScheduledTitle"
        :description="getScheduledDescription"
      />
    </div>
  </div>
</template>
<script>
import ManualBillingForm from "./ManualBillingForm.vue";
import BillingItem from "./BillingItem.vue";
import BankDetails from "./BankDetails.vue";
import accountMixin from "../../../../../mixins/account";
import { mapGetters } from "vuex";

export default {
  components: { ManualBillingForm, BankDetails, BillingItem },
  mixins: { accountMixin },
  data() {
    return {
      allowCreation: false,
    };
  },
  computed: {
    ...mapGetters({
      subscription: "subscription/getSubscription",
      uiFlags: "subscription/getUIFlags",
    }),
    isSubscriptionSchedualed() {
      return (
        (this.subscription.scheduled_plan_name &&
          this.subscription.type === "bank") ||
        false
      );
    },
    getButtonText() {
      return this.isSubscriptionSchedualed
        ? ""
        : this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.CURRENT_PLAN.BUTTON_TEXT");
    },
    getDescription() {
      const { plan_name = "",  plan_interval = '' } = this.subscription;
      if (!plan_name) {
        return this.$t("BILLING_SETTINGS.FORM.CURRENT_PLAN.NO_PLAN");
      }
      if (plan_name === "expired") {
        return this.$t("BILLING_SETTINGS.FORM.CURRENT_PLAN.PLAN_EXPIRED");
      }
      if (
        !!plan_name &&
        plan_name !== "expired"
      ) {
        let planDescription = this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.CURRENT_PLAN.DESCRIPTION",{ plan: plan_name, currentInterval: plan_interval});
        let planValidity = this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.CURRENT_PLAN.FREE_PLAN_VALIDITY");
        let plan_discription = `${planDescription} ${ accountMixin.methods.getFormatedName(plan_name) === "free" ? planValidity : ''}`
        return  plan_discription;
      }
    },
    getTitle() {
      if (!this.subscription.plan_name) {
        return "";
      }
      return this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.CURRENT_PLAN.TITLE");
    },
    getScheduledTitle() {
      const { scheduled_plan_name, scheduled_plan_status, scheduled_plan_interval = '' } = this.subscription;
      return this.$t(
        "BILLING_SETTINGS.MANUAL_SETTINGS.SCHEDULED_PLAN.TITLE",
        { plan: scheduled_plan_name, currentInterval: scheduled_plan_interval, status: scheduled_plan_status },
      );
    },
    getScheduledDescription() {
      const { scheduled_plan_name, scheduled_plan_interval = '' } = this.subscription;
      return this.$t(
        "BILLING_SETTINGS.MANUAL_SETTINGS.SCHEDULED_PLAN.DESCRIPTION",
          { plan: scheduled_plan_name, currentInterval: scheduled_plan_interval }
      );
    },
  },
  mounted(){
    this.getSuperAdminAccountDetails();
  },
  methods: {
    getDateTime(dateTime) {
      return new Date(dateTime).toDateString();
    },
    async getSuperAdminAccountDetails() {
      try {
        await this.$store.dispatch("subscription/getSuperAdminAccountDetails");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
