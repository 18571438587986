<template>
  <div
    class="flex flex-col items-center w-full ml-0 lg:ml-5 lg:w-3/5 order-first lg:order-last"
  >
    <h2
      class="text-2xl font-semibold text-slate-800 dark:text-slate-100 mb-4 w-full lg:w-4/5"
    >
      {{ $t("BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.TITLE") }}
    </h2>
    <!-- account details -->
    <div class="flex flex-col mt-5 justify-center items-center w-full">
        <div
          @click="isInstructionsOpen = !isInstructionsOpen"
          class="p-4 w-full lg:w-4/5 rounded-t-md flex justify-between items-center cursor-pointer bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 shadow-lg md:shadow-xl"
        >
          <div class="flex items-center gap-2">
            <h4
              class="font-medium font-semibold text-sm text-slate-800 dark:text-slate-100"
            >
              {{
                $t(
                  "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.HEADING"
                )
              }}
            </h4>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        <div
          v-if="isInstructionsOpen"
          @click.outside="open = false"
          class="w-full lg:w-4/5 bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 shadow-lg md:shadow-xl p-4 transition ease-in-out duration-300 rounded-b-md"
        >
        <div v-if="!!accountDetails.account_number">
          <div class="mb-6">
            <h3 class="text-xl font-bold mb-2 text-slate-800 dark:text-slate-100">
              {{
                $t(
                  "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.TITLE"
                )
              }}
            </h3>
          </div>
          <h6 class="text-md font-bold text-woot-500 dark:text-woot-500">
            {{
              $t("BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.STEP-1")
            }}
          </h6>
          <h6 class="text-md font-bold text-woot-500 dark:text-woot-500">
            {{
              $t("BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.STEP-2")
            }}
          </h6>
          <span class="text-sm font-bold text-slate-800 dark:text-slate-100">{{
            $t(
              "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.Bank_Name"
            )
          }}</span>
          <p>
              {{accountDetails.bank_name}}
          </p>
          <span class="text-sm font-bold text-slate-800 dark:text-slate-100">{{
            $t(
              "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.Bank_Title"
            )
          }}</span>
          <p>
              {{accountDetails.title}}
          </p>
          <span class="text-sm font-bold text-slate-800 dark:text-slate-100">{{
            $t(
              "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.Account_Number"
            )
          }}</span>
          <p>
             {{accountDetails.account_number}}
          </p>
          <span class="text-sm font-bold text-slate-800 dark:text-slate-100">{{
            $t(
              "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.IBAN"
            )
          }}</span>
          <p>
            {{accountDetails.iban}}
          </p>
        </div>
          <div v-else> <h1 class="font-medium font-semibold text-sm text-slate-800 dark:text-slate-100">{{
            $t(
              "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.FALLBACK"
            )
          }}</h1> </div>

        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isInstructionsOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      accountDetails: "subscription/getAccountDetails",
    }),
  }
};
</script>
