<template>
  <woot-loading-state v-if="isLoading" />
  <div class="columns profile--settings" v-else>
    <woot-button
      v-show="isEditButtonShow"
      color-scheme="success"
      class-names="button--fixed-top"
      icon="edit"
      @click="editPaymentMethod"
    >
      {{ $t("BILLING_SETTINGS.BILLING_ITEMS.PAYMENT_METHOD_UPDATE.BTN_TXT") }}
    </woot-button>
    <!-- :class="{'pointer-events-none': subscriptionStatus}" -->
    <!-- <ul
      class="relative w-full lg:w-2/5 flex flex-wrap p-1 list-none rounded-xl"
    >
      <li
        v-for="(tab, index) in settingTabs"
        :key="index"
        class="flex-auto text-center p-5 font-semibold"
      >
        <a
          @click="activeTab = index"
          class="flex items-center justify-center w-full px-0 py-3 mb-0 transition-all ease-in-out border-0 rounded-md cursor-pointer shadow-md"
          :class="{
            'text-blue-600 bg-white border border-solid border-slate-75 dark:border-slate-700/50 shadow-lg md:shadow-xl':
              activeTab === index,
            'text-slate-800 dark:text-slate-100 bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 shadow-lg md:shadow-xl':
              activeTab !== index,
          }"
        >
          <span class="ml-1">{{ tab }}</span>
        </a>
      </li>
    </ul> -->
    <!-- 'pointer-events-none': subscriptionStatus, -->
    <div data-tab-content="" class="p-5">
      <Billing v-if="!isLoading && !localBankAllow" />
      <manual-billing v-if="!isLoading && localBankAllow" />
      <!-- <create-add-on v-if="activeTab === 2"/> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Billing from "./components/Billing.vue";
import ManualBilling from "./components/ManualBilling.vue";
// import CreateAddOn from "./components/CreateAddOn.vue";
import alertMixin from "shared/mixins/alertMixin";
import configMixin from "shared/mixins/configMixin";
import accountMixin from "../../../../mixins/account";
import debounceMixin from "shared/mixins/debounceMixin";
export default {
  mixins: [accountMixin, alertMixin, configMixin, debounceMixin],
  components: { Billing, ManualBilling },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: "globalConfig/get",
      getAccount: "accounts/getAccount",
      subscription: "subscription/getSubscription",
      subscriptionStatus: "subscription/getSubscriptionStatus",
      localBankAllow: "subscription/localBankAllow",
      uiFlags: "accounts/getUIFlags",
    }),
    isLoading(){
      return this.uiFlags.isFetchingItem || this.uiFlags.isFetching;
    },
    settingTabs() {
      return this.$t("BILLING_SETTINGS.TABS");
    },
    isEditButtonShow() {
      return (
        !!this.subscription.plan_name &&
        this.getFormatedName(this.subscription.plan_name) !== "free" &&
        this.subscription.plan_name !== "expired" &&
        !this.localBankAllow
      );
    },
  },
  mounted() {
    try {
      this.$store.dispatch("subscription/checkCurrentCountry");
    } catch (error) {
      console.log("checkCurrentCountry in index", error)
    }
  },
  methods: {
    setActiveTab() {
      this.activeTab = 0;
    },
    editPaymentMethod() {
      this.debouncedHandler(this.updatePaymentMethod);
    },

    async updatePaymentMethod() {
      try {
        if (!this.accountId) {
          this.showAlert("Unable to determine account ID");
          return;
        }
        const payload = { id: this.accountId };
        const { url = "" } = await this.$store.dispatch(
          "subscription/editPaymentMethod",
          payload
        );
        if (url) {
          window.location.href = url;
        }
        this.showAlert(
          this.$t(
            "BILLING_SETTINGS.BILLING_ITEMS.PAYMENT_METHOD_UPDATE.API.SUCCESS_MESSAGE"
          )
        );
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        } else {
          this.showAlert(
            this.$t(
              "BILLING_SETTINGS.BILLING_ITEMS.PAYMENT_METHOD_UPDATE.API.ERROR_MESSAGE"
            )
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~dashboard/assets/scss/variables.scss";
@import "~dashboard/assets/scss/mixins.scss";
.profile--settings {
  padding: 24px;
  overflow: auto;
}
.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}
</style>
