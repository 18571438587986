<template>
  <div
    class="flex flex-col md:flex-row justify-between items-start md:items-center p-10 bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 mb-4"
    :id="type"
  >
    <div class="flex items-center justify-start m-0 mx-4 flex-1">
      <div>
        <h3
          class="text-xl text-slate-800 dark:text-slate-100 font-bold"
          v-if="title"
        >
          {{ title }}
          <span
            class="font-extrabold text-woot-500 dark:text-woot-500"
            v-if="['create', 'upgrade'].includes(type)"
            >{{ `(${subscription.plan_name})` }}</span
          >
        </h3>
        <p>
          {{ description }}
        </p>
      </div>
    </div>
    <div class="flex justify-center items-center mb-0 w-[15%]">
      <woot-submit-button
        v-if="
          ['upgrade', 'downgrade'].includes(type) &&
          !!actionButtonText &&
          plansAvailable
        "
        @click="openPopup"
        :button-text="actionButtonText"
      />
      <woot-submit-button
        v-if="type === 'cancel'"
        @click="openDeletePopup"
        :button-text="actionButtonText"
        button-class="smooth alert"
      />
      <woot-submit-button
        v-if="type === 'create' && !!actionButtonText"
        @click="$emit('createLocalTransfer')"
        :button-text="actionButtonText"
      />
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="getDelModalContent.title"
      :message="getDelModalContent.description"
      :confirm-text="getDelModalContent.confirmText"
      :reject-text="getDelModalContent.rejectText"
    />
    <!-- used for getting confirmation on subscription -->
    <woot-delete-modal
      :show.sync="showConfirmationPopup"
      :on-close="closeSubscriptionConfirmationPopup"
      :on-confirm="confirmSubscription"
      :title="getSubConfirmationModalContent.title"
      :message="getSubConfirmationModalContent.description"
      :confirm-text="getSubConfirmationModalContent.confirmText"
      :reject-text="getSubConfirmationModalContent.rejectText"
    />
    <div class="max-w-sm">
      <woot-modal
        :show.sync="showUpDownGradeModal"
        :on-close="closePopup"
        size="extra-samll"
      >
        <UpDownGradeForm
          v-if="type === 'upgrade'"
          formTitle="Upgrade Plan"
          :type="type"
          :checkboxLabel="'Immediate Upgrade'"
          @closePopup="closePopup"
          @openSubscriptionConfirmationPopup="openSubscriptionConfirmationPopup"
        />
        <UpDownGradeForm
          v-if="type === 'downgrade'"
          formTitle="Downgrade Plan"
          :type="type"
          @closePopup="closePopup"
          @openSubscriptionConfirmationPopup="openSubscriptionConfirmationPopup"
        />
      </woot-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import globalConfigMixin from "shared/mixins/globalConfigMixin";
import UpDownGradeForm from "./UpDownGradeForm.vue";
import debounceMixin from "shared/mixins/debounceMixin";
import accountMixin from "../../../../../mixins/account";
import subscription from "../../../../../store/modules/subscription";

export default {
  mixins: [alertMixin, globalConfigMixin, debounceMixin, accountMixin],
  components: { UpDownGradeForm },
  props: {
    title: { type: String, default: "" },
    type: { type: String, default: "" },
    description: { type: String, default: "" },
    actionButtonText: { type: String, default: "" },
    deleteConfirmationText: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showUpDownGradeModal: false,
      showDeleteConfirmationPopup: false,
      account: "",
      showActionButton: true,
      showConfirmationPopup: false,
      immediate: false,
      selectedDuration: "",
      selectedPlanId: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      accountId: "getCurrentAccountId",
      globalConfig: "globalConfig/get",
      subscription: "subscription/getSubscription",
      activeSubscription: "subscription/getActiveSubscription",
      getAccount: "accounts/getAccount",
    }),
    plansAvailable() {
      return this.getPlans(this.type)?.length;
    },
    getDelModalContent() {
      if (
        this.account &&
        this.subscription.refund_period &&
        !this.account.refund_availed
      ) {
        const { TITLE, DESCRIPTION, CONFIRM_BUTTON_TEXT, REJECT_BUTTON_TEXT } =
          this.$t("BILLING_SETTINGS.BILLING_ITEMS.REFUND_CONFIRMATION");
        return {
          title: TITLE,
          description: DESCRIPTION,
          confirmText: CONFIRM_BUTTON_TEXT,
          rejectText: REJECT_BUTTON_TEXT,
        };
      } else {
        const { TITLE, DESCRIPTION, CONFIRM_BUTTON_TEXT, REJECT_BUTTON_TEXT } =
          this.$t("BILLING_SETTINGS.BILLING_ITEMS.CANCEL_CONFIRMATION");
        return {
          title: TITLE,
          description: DESCRIPTION,
          confirmText: CONFIRM_BUTTON_TEXT,
          rejectText: REJECT_BUTTON_TEXT,
        };
      }
    },
    getSubConfirmationModalContent() {
      if (this.immediate) {
        const { TITLE, DESCRIPTION, CONFIRM_BUTTON_TEXT, REJECT_BUTTON_TEXT } =
          this.$t(
            "BILLING_SETTINGS.BILLING_ITEMS.IMMEDIATE_SUBSCRIPTION_CONFIRMATION"
          );
        return {
          title: TITLE,
          description: DESCRIPTION,
          confirmText: CONFIRM_BUTTON_TEXT,
          rejectText: REJECT_BUTTON_TEXT,
        };
      } else {
        const currentInterval =
          !this.subscription.subscription_cancellation_status &&
          this.subscription.scheduled_interval
            ? this.subscription.scheduled_interval
            : this.subscription.plan_interval;
        const { TITLE, CONFIRM_BUTTON_TEXT, REJECT_BUTTON_TEXT } = this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.INTERVAL_CHANGE_CONFIRMATION"
        );
        return {
          title: TITLE,
          description: this.$t(
            "BILLING_SETTINGS.BILLING_ITEMS.INTERVAL_CHANGE_CONFIRMATION.DESCRIPTION",
            {
              currentInterval: currentInterval,
              newInterval: this.selectedDuration,
            }
          ),
          confirmText: CONFIRM_BUTTON_TEXT,
          rejectText: REJECT_BUTTON_TEXT,
        };
      }
    },
  },
  mounted() {
    this.account = this.getAccount(this.accountId);
  },
  methods: {
    openDeletePopup() {
      this.showDeleteConfirmationPopup = true;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    openSubscriptionConfirmationPopup(
      immediate,
      selectedDuration,
      selectedPlanId
    ) {
      this.immediate = immediate;
      this.selectedDuration = selectedDuration;
      this.selectedPlanId = selectedPlanId;
      this.closePopup();
      setTimeout(() => {
        this.showConfirmationPopup = true;
      }, 200);
    },
    closeSubscriptionConfirmationPopup() {
      this.immediate = "";
      this.selectedDuration = "";
      this.selectedPlanId = "";
      this.showConfirmationPopup = false;
    },
    closePopup() {
      this.showUpDownGradeModal = false;
    },
    openPopup() {
      this.showUpDownGradeModal = true;
    },
    confirmDeletion() {
      this.debouncedHandler(this.cancelSubscription);
    },
    confirmSubscription() {
      this.debouncedHandler(this.submitSubscription);
    },
    async cancelSubscription() {
      try {
        if (!this.accountId) {
          this.showAlert("Unable to determine account ID");
          return;
        }
        const payload = { id: this.accountId };
        await this.$store.dispatch("subscription/cancelSubscription", payload);
        if(!this.account.refund_availed) {
          this.showAlert(this.$t("BILLING_SETTINGS.BILLING_ITEMS.REFUND_CONFIRMATION.API.SUCCESS_MESSAGE"));  
        }else {
          this.showAlert(this.$t("BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SUBSCRIPTION.API.SUCCESS_MESSAGE"));
        }
        this.closeDeletePopup();
      } catch (error) {
        this.closeDeletePopup();
        if (error.message) {
          this.showAlert(error.message);
        } else {
          this.showAlert(this.$t("BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SUBSCRIPTION.API.ERROR_MESSAGE"));
        }
      }
    },
  },
};
</script>
