import ApiClient from "../../ApiClient";

// FOR GETTING NEW QR CODE
class QrRequest extends ApiClient {
  constructor() {
    super("channels/unofficial_whatsapp_channel/reconnect_instance", {
      accountScoped: true,
    });
  }

  reconnectInbox({ instance_name }) {
    const options = {
      method: "GET",
      url: this.url,
    };
    if (instance_name) {
      options.params = { instance_name };
    }
    return axios(options);
  }
}

export default new QrRequest();
