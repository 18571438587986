<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.instance.$error }">
          {{ $t("INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.DEVICE_NAME.LABEL") }}
          <input
            v-model.trim="instance"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.DEVICE_NAME.PLACEHOLDER')
            "
            @blur="$v.instance.$touch"
          />
          <span v-if="$v.instance.$error" class="message">
            {{ $t("INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.DEVICE_NAME.ERROR") }}
          </span>
        </label>
        <label
          :class="{
            error: isPhoneNumberNotValid,
          }"
        >
          {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
          <woot-phone-input
            v-model="phone_number"
            :value="phone_number"
            :error="isPhoneNumberNotValid"
            :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
            @input="onPhoneNumberInputChange"
            @blur="$v.phone_number.$touch"
            @setCode="setPhoneCode"
          />
          <span v-if="isPhoneNumberNotValid" class="message">
            {{ phoneNumberError }}
          </span>
        </label>
        <div
          v-if="isPhoneNumberNotValid || !phone_number"
          class="callout small warning text-sm dark:bg-yellow-200/20 dark:text-yellow-400"
        >
          {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP') }}
        </div>
      </div>
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :disabled="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import inboxMixin from 'shared/mixins/inboxMixin';
import debounceMixin from "shared/mixins/debounceMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import PageHeader from "../../SettingsSubPageHeader.vue";
import { validateChannelName, isPhoneNumberValid } from "shared/helpers/Validators";
import countries from 'shared/constants/countries.js';
import parsePhoneNumber from 'libphonenumber-js';

export default {
  mixins: [alertMixin, debounceMixin, inboxMixin],
  components: {
    PageHeader,
  },
  data() {
    return {
      instance: "",
      phone_number: '',
      countries: countries,
      activeDialCode: '',
    };
  },
  validations: {
    instance: { required, maxLength: maxLength(20), validateChannelName },
    phone_number: {
      required,
    },
  },
  mounted() {
    this.setDialCode()
  },
  beforeDestroy() {
    bus.$emit("closeLimitExceededToastMessage");
  },
  computed: {
    ...mapGetters({ uiFlags: "inboxes/getUIFlags", currentAccountId: 'getCurrentAccountId', }),
    isPhoneNumberNotValid() {
      if (this.phone_number !== '') {
        return (
          !isPhoneNumberValid(this.phone_number, this.activeDialCode) ||
          (this.phone_number !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phone_number, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.phone_number);
    }
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid || this.isPhoneNumberNotValid) {
        return;
      }
      this.debouncedHandler(this.createChannelCallback)
    },
    setDialCode() {
      if (
        this.phone_number !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      }
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setPhoneCode(code) {
      if (this.phone_number !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phone_number.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phone_number = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },

    async createChannelCallback() {
         try {
        const unofficialWhatsappChannel = await this.createUnofficialWhatsappChannel(
          this.instance,
          this.phone_number,
          this.currentAccountId
        );
        this.$router.replace({
            name: "settings_inboxes_add_agents",
            params: {
              page: "new",
              inbox_id: unofficialWhatsappChannel?.inbox_id,
            },
          });
          
          this.$store.dispatch('inboxes/get');

      } catch (error) {
        if(error.message === 'Inbox limit has been exceeded. Please upgrade your plan to continue.') {
          bus.$emit('limitExceededToastMessage')
          return;
        }
        this.showAlert(error.message);
      }
    }
  },
};
</script>
