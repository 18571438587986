<template>
  <div
    class="flex flex-col mt-8 lg:mt-0 bg-gray-100 lg:w-2/5 order-last lg:order-first"
  >
    <h2 class="text-2xl font-semibold mb-4 text-slate-800 dark:text-slate-100">
      {{ $t("BILLING_SETTINGS.MANUAL_SETTINGS.FORM.TITLE") }}
    </h2>
    <form @submit.prevent="submitSubscription()">
      <div
        v-for="field in formFields"
        :key="field.NAME"
        :for="field.NAME"
        class="block text-sm font-medium text-gray-600"
      >
        <label
          class="text-slate-800 dark:text-slate-100"
          :class="{ error: $v.formData[field.NAME].$error }"
        >
          {{ field.LABEL }}
          <input
            v-if="field.TYPE !== 'select'"
            :type="field.TYPE"
            :id="field.NAME"
            :name="field.NAME"
            v-model.trim="formData[field.NAME]"
            :readonly="field.READ_ONLY ? true : false"
            class="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
            @blur="isValidate(field.NAME)"
            @input="isValidate(field.NAME)"
          />
          <FormSelect
            v-if="field.TYPE === 'select' && field.NAME === 'subscription_type'"
            :options="$t('BILLING_SETTINGS.DURATION')"
            id="selectDuration"
            v-model.trim="formData[field.NAME]"
            :selectedDuration="formData[field.NAME]"
            @validate="isValidate(formData[field.NAME])"
          />
          <FormSelect
            v-if="
              field.TYPE === 'select' && field.NAME === 'billing_product_id'
            "
            :options="getPlans('localBankTransfer')"
            id="selectPlan"
            v-model.trim="formData[field.NAME]"
            :selectedDuration="formData['subscription_type']"
            @validate="isValidate(formData[field.NAME])"
          />
          <span
            v-if="$v.formData[field.NAME].$error"
            :class="{ 'text-red-600': $v.formData[field.NAME].$error }"
          >
            {{ field.ERROR }}
          </span>
        </label>
      </div>
      <div class="mt-8 w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :disabled="uiFlags.isCreating"
          :button-text="$t('BILLING_SETTINGS.MANUAL_SETTINGS.FORM.SUBMIT')"
        />
        <woot-button
          v-if="true"
          @click.prevent="$emit('backToDetails')"
          variant="smooth"
          >{{ $t("BILLING_SETTINGS.MANUAL_SETTINGS.BACK") }}</woot-button
        >
      </div>
    </form>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="getDelModalContent.title"
      :message="getDelModalContent.description"
      :confirm-text="getDelModalContent.confirmText"
      :reject-text="getDelModalContent.rejectText"
    />
  </div>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import FormSelect from "./FormSelect.vue";
import { customAccountNumberValidation } from "shared/helpers/Validators";
import alertMixin from "shared/mixins/alertMixin";
import accountMixin from "../../../../../mixins/account";
import debounceMixin from "shared/mixins/debounceMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [alertMixin, accountMixin, debounceMixin],
  components: { FormSelect },
  data() {
    return {
      formData: {
        bank_title: "",
        account_title: "",
        account_number: "",
        billing_product_id: "",
        status: "pending",
        subscription_type: "",
        reference: "",
      },
      showDeleteConfirmationPopup: false,
      allowCreation: false,
    };
  },
  validations: {
    formData: {
      bank_title: { required, maxLength: maxLength(20) },
      account_title: { required, maxLength: maxLength(25) },
      account_number: { required, customAccountNumberValidation },
      billing_product_id: { required },
      subscription_type: { required },
      reference: { required },
    },
  },
  computed: {
    ...mapGetters({
      accountId: "getCurrentAccountId",
      uiFlags: "subscription/getUIFlags",
      subscription: "subscription/getSubscription",
    }),
    actionButtonText() {
      return this.$t(
        "BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SUBSCRIPTION.BUTTON_TEXT"
      );
    },
    formFields() {
      return this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.FORM.FIELDS");
    },
    durations() {
      return this.$t("BILLING_SETTINGS.DURATION");
    },
    subscriptionPlans() {
      return this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.PLANS.LIST");
    },
    getDelModalContent() {
      return {
        title: this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.CANCEL_CONFIRMATION.TITLE"
        ),
        description: this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.CANCEL_CONFIRMATION.DESCRIPTION"
        ),
        confirmText: this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.CANCEL_CONFIRMATION.CONFIRM_BUTTON_TEXT"
        ),
        rejectText: this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.CANCEL_CONFIRMATION.REJECT_BUTTON_TEXT"
        ),
      };
    },
  },
  created() {
    this.formData.reference = `gueswi-${this.accountId}`;
  },
  methods: {
    submitSubscription() {
      this.debouncedHandler(this.submitForm);
    },
    async submitForm() {
      this.$v.formData?.$touch();
      if (this.$v?.$invalid) {
        return;
      }
      try {
        const payload = {
          ...this.formData,
          account_id: this.accountId,
          status: "pending",
        };

        await this.$store.dispatch("subscription/localBankTransfer", {
          local_bank_transfer: payload,
        });
        this.showAlert(
          this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.API.SUCCESS_MESSAGE")
        );
        this.initializeAccountBillingSubscription();
        this.$emit("backToDetails");
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }else{
          this.showAlert(
            this.$t("BILLING_SETTINGS.MANUAL_SETTINGS.API.ERROR_MESSAGE")
          );
        }
      }
    },
    isValidate(fieldName) {
      this.$v.formData[fieldName]?.$touch();
      if (this.$v.$pending) {
        return;
      }
      if (this.$v.$invalid) {
        return;
      }
    },
    openDeletePopup() {
      this.showDeleteConfirmationPopup = true;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      // this.debouncedHandler(this.cancelSubscription)
    },
    async cancelSubscription() {
      try {
        if (!this.accountId) {
          this.showAlert("Unable to determine account ID");
          return;
        }
        const payload = { id: this.accountId };
        await this.$store.dispatch("subscription/cancelSubscription", payload);
        this.showAlert(this.$t("BILLING_SETTINGS.FORM.API.SUCCESS_MESSAGE"));
        this.closeDeletePopup();
        window.location.reload();
      } catch (error) {
        this.closeDeletePopup();
        if (error.message) {
          this.showAlert(error.message);
        } else {
          this.showAlert(this.$t("BILLING_SETTINGS.FORM.API.ERROR_MESSAGE"));
        }
      }
    },
  },
};
</script>
