<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="showNotification" class="ui-notification-container">
      <div class="ui-notification">
        <div class="text-section">
          <p class="ui-notification-text">
            {{ $t("ONBOARDING.EMAIL_NOTIFICATION") }}
          </p>
          <woot-button
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="arrow-right"
            :isLoading="uiFlags.isUpdating"
            :isDisabled="uiFlags.isUpdating"
            @click="reSendEmail"
          >
            {{ $t("ONBOARDING.RESEND_CONFIRMATION_EMAIL.BUTTON_TEXT") }}
          </woot-button>
        </div>
        <div class="btn-section">
          <woot-button
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="dismiss-circle"
            @click="closeOnBoardingNotification"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import debounceMixin from "shared/mixins/debounceMixin";
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [ debounceMixin, alertMixin ],

  data() {
    return {
      showNotification: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: "accounts/getUIFlags",
    }),
  },
  mounted() {
    bus.$on("onBoardingNotification", this.onBoardingNotification);
    bus.$on("closeOnBoardingNotification", this.closeOnBoardingNotification);
  },
  beforeDestroy() {
    bus.$off("onBoardingNotification", this.onNewToastMessage);
    bus.$off("closeOnBoardingNotification ", this.closeOnBoardingNotification);
  },
  methods: {
    onBoardingNotification() {
      this.showNotification = true;
    },
    closeOnBoardingNotification() {
      this.showNotification = false;
    },
    async reSendEmailCallback() {
      try {
        await this.$store.dispatch("accounts/reSendConfirmationEmail");
          this.showAlert(this.$t("ONBOARDING.RESEND_CONFIRMATION_EMAIL.API.SUCCESS_MESSAGE"));
          this.closeOnBoardingNotification();  
      } catch (error) {
        console.log("reSendEmailCallback ------------------------ error", error)
        this.showAlert(this.$t("ONBOARDING.RESEND_CONFIRMATION_EMAIL.API.ERROR_MESSAGE"));
      }
    },
    reSendEmail() {
      this.debouncedHandler(this.reSendEmailCallback);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~dashboard/assets/scss/mixins";

.ui-notification-container {
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-index-much-higher);
}

.ui-notification {
  @apply text-white dark:text-white font-bold;
  background-color: var(--w-500);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  box-shadow: var(--shadow-large);
  padding: var(--space-small);
}
.text-section {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-section {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.ui-notification-text {
  margin: 0 var(--space-small);
}
</style>
