import * as types from "../mutation-types";
import AccountAPI from "../../api/account";
import { throwErrorMessage } from "../utils/api";

const state = {
  records: [],
  uiFlags: {
    isCreating: false,
    isFetching: false,
    isFetchingItem: false,
    isUpdating: false,
    isDeleting: false,
  },
  in_venezuela: false,
  adminAccuntDetails: {}
};

export const getters = {
  getSubscription($state) {
    return $state.records[0] || {};
  },
  getSubscriptionStatus($state) {
    return !!(
      ["completed", "pending", "true"].includes(
        $state.records[0]?.plan_status
      ) && $state.records[0]?.plan_name !== "Trial"
    );
  },
  getActiveSubscription($state) {
    return (
        $state.records[0].available_product_prices.find(
        (prod) => prod.plan === $state.records[0].plan_name
      ) || {}
    );
  },
  getSelectedSubscription: $state => id => {
    return (
        $state.records[0].available_product_prices.find(
        (prod) => prod.id === id
      ) || {}
    );
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  localBankAllow($state){
    return $state.in_venezuela;
  },
  getAccountDetails($state) {
    return $state.adminAccuntDetails;
  },
};

export const actions = {
  getBillingSubscription: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await AccountAPI.getBillingSubscription();
      commit(types.default.ADD_ACCOUNT, response.data);
      return response?.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  getSuperAdminAccountDetails: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await AccountAPI.getAccountDetails();
      commit(types.default.ADMIN_ACC_DETAILS, response.data);
      return response?.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  submitSubscription: async ({ commit }, data) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AccountAPI.startBillingSubscription(data);
      return response?.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
    }
  },
  localBankTransfer: async ({ commit }, data) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: true });
    try {
      const response = await AccountAPI.localBankTransfer(data);
      return response?.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
    }
  },
  editPaymentMethod: async ({ commit }, data) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AccountAPI.updatePaymentMethod(data);
      return response?.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
    }
  },
  cancelSubscription: async ({ commit, dispatch }, data) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isDeleting: true });
    try {
      const response = await AccountAPI.cancelBillingSubscription(data);
      await dispatch("getBillingSubscription");
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isDeleting: false });
    }
  },
  checkCurrentCountry: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isFetching: true });
    try {
      const response = await AccountAPI.getCurrentCountry();
      commit(types.default.CURRENT_COUNTRY, response.data);
      return response?.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetching: false,
      });
    }
  },
};

export const mutations = {
  [types.default.ADD_ACCOUNT]($state, data) {
    $state.records = [data];
  },
  [types.default.CURRENT_COUNTRY]($state, data) {
    $state.in_venezuela = data.in_venezuela;
  },
  [types.default.ADMIN_ACC_DETAILS]($state, data) {
    $state.adminAccuntDetails = data;
  },
  [types.default.SET_ACCOUNT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
