<template>
  <div
    class="flex flex-row overflow-auto p-4 h-full bg-slate-25 dark:bg-slate-800"
  >
    <woot-wizard
      class="hide-for-small-only w-[25%]"
      :global-config="globalConfig"
      :items="items"
    />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  mixins: [globalConfigMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    items() {
      const createFlow = this.$t("INBOX_MGMT.CREATE_FLOW");
      return createFlow.map((item) =>
          this.transformItemForUnofficialWhatsapp(item)
        );
      // if (this.$route.params.sub_page === "unofficialWhatsapp") {
        // return createFlow.map((item) =>
        //   this.transformItemForUnofficialWhatsapp(item)
        // );
      // } else {
        // return createFlow.filter((item) =>
        //   this.transformItemForOtherPages(item)
        // );
      // }
    },
  },
  methods: {
    transformItemForUnofficialWhatsapp(item) {
      return {
        ...item,
        body: this.useInstallationName(
          item.body,
          this.globalConfig.installationName
        ),
      };
    },

    transformItemForOtherPages(item) {
      return (
        item.title !== "Connect Device" && {
          ...item,
          body: this.useInstallationName(
            item.body,
            this.globalConfig.installationName
          ),
        }
      );
    },
  },
};
</script>
