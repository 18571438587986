var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center w-full ml-0 lg:ml-5 lg:w-3/5 order-first lg:order-last"},[_c('h2',{staticClass:"text-2xl font-semibold text-slate-800 dark:text-slate-100 mb-4 w-full lg:w-4/5"},[_vm._v("\n    "+_vm._s(_vm.$t("BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.TITLE"))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col mt-5 justify-center items-center w-full"},[_c('div',{staticClass:"p-4 w-full lg:w-4/5 rounded-t-md flex justify-between items-center cursor-pointer bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 shadow-lg md:shadow-xl",on:{"click":function($event){_vm.isInstructionsOpen = !_vm.isInstructionsOpen}}},[_c('div',{staticClass:"flex items-center gap-2"},[_c('h4',{staticClass:"font-medium font-semibold text-sm text-slate-800 dark:text-slate-100"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.HEADING"
              ))+"\n          ")])]),_vm._v(" "),_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M19 9l-7 7-7-7"}})])]),_vm._v(" "),(_vm.isInstructionsOpen)?_c('div',{staticClass:"w-full lg:w-4/5 bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 shadow-lg md:shadow-xl p-4 transition ease-in-out duration-300 rounded-b-md",on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"outside",undefined,$event.key,undefined))return null;_vm.open = false}}},[(!!_vm.accountDetails.account_number)?_c('div',[_c('div',{staticClass:"mb-6"},[_c('h3',{staticClass:"text-xl font-bold mb-2 text-slate-800 dark:text-slate-100"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.TITLE"
              ))+"\n          ")])]),_vm._v(" "),_c('h6',{staticClass:"text-md font-bold text-woot-500 dark:text-woot-500"},[_vm._v("\n          "+_vm._s(_vm.$t("BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.STEP-1"))+"\n        ")]),_vm._v(" "),_c('h6',{staticClass:"text-md font-bold text-woot-500 dark:text-woot-500"},[_vm._v("\n          "+_vm._s(_vm.$t("BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.STEP-2"))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"text-sm font-bold text-slate-800 dark:text-slate-100"},[_vm._v(_vm._s(_vm.$t(
            "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.Bank_Name"
          )))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.accountDetails.bank_name)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"text-sm font-bold text-slate-800 dark:text-slate-100"},[_vm._v(_vm._s(_vm.$t(
            "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.Bank_Title"
          )))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.accountDetails.title)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"text-sm font-bold text-slate-800 dark:text-slate-100"},[_vm._v(_vm._s(_vm.$t(
            "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.Account_Number"
          )))]),_vm._v(" "),_c('p',[_vm._v("\n           "+_vm._s(_vm.accountDetails.account_number)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"text-sm font-bold text-slate-800 dark:text-slate-100"},[_vm._v(_vm._s(_vm.$t(
            "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.LABELS.IBAN"
          )))]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.accountDetails.iban)+"\n        ")])]):_c('div',[_c('h1',{staticClass:"font-medium font-semibold text-sm text-slate-800 dark:text-slate-100"},[_vm._v(_vm._s(_vm.$t(
            "BILLING_SETTINGS.MANUAL_SETTINGS.PAYMENT_INSTRUCTIONS.BANK_DETAILS.FALLBACK"
          )))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }