<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="showNotification" class="ui-notification-container">
      <div class="ui-notification">
        <div class="text-section">
          <p class="ui-notification-text">
            {{ $t("BILLING_SETTINGS.PLAN_LIMIT_EXCEEDED.NOTIFICATION") }}
          </p>
          <woot-button
            size="tiny"
            color-scheme="alert"
            icon="arrow-right"
            @click="reDirectToBilling"
          >
            {{ $t("BILLING_SETTINGS.PLAN_LIMIT_EXCEEDED.BUTTON_TEXT") }}
          </woot-button>
        </div>
        <div class="btn-section">
          <woot-button
            variant="smooth"
            size="tiny"
            color-scheme="warning"
            icon="dismiss-circle"
            @click="closeLimitExceededToastMessage"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import globalConfigMixin from "shared/mixins/globalConfigMixin";
import { mapGetters } from "vuex";
import debounceMixin from "shared/mixins/debounceMixin";
import alertMixin from "shared/mixins/alertMixin";
import inboxMixin from "shared/mixins/inboxMixin";

export default {
  mixins: [globalConfigMixin, debounceMixin, alertMixin, inboxMixin],

  data() {
    return {
      showNotification: false,
    };
  },

  computed: {
    ...mapGetters({
      globalConfig: "globalConfig/get",
      activeInbox: "getSelectedInbox",
      uiFlags: "inboxes/getUIFlags",
    }),
    inbox() {
      return this.$store.getters["inboxes/getInbox"](this.activeInbox);
    },
  },

  mounted() {
    bus.$on("limitExceededToastMessage", this.limitExceededToastMessage);
    bus.$on(
      "closeLimitExceededToastMessage",
      this.closeLimitExceededToastMessage
    );
  },
  beforeDestroy() {
    bus.$off("limitExceededToastMessage", this.limitExceededToastMessage);
    bus.$off(
      "closeLimitExceededToastMessage",
      this.closeLimitExceededToastMessage
    );
  },
  methods: {
    limitExceededToastMessage() {
      this.showNotification = true;
    },
    closeLimitExceededToastMessage() {
      this.showNotification = false;
    },
    async reDirectToBilling() {
      this.$router.push({ name: "billing_settings_index" });
      this.closeLimitExceededToastMessage();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~dashboard/assets/scss/mixins";

.ui-notification-container {
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-index-highest);
}

.ui-notification {
  @apply bg-red-500 dark:bg-red-700 text-white dark:text-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  box-shadow: var(--shadow-large);
  padding: var(--space-small);
}
.text-section {
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}
.btn-section {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.ui-notification-text {
  margin: 0 var(--space-small);
}
.edit-btn {
  @apply px-2 py-2 underline cursor-pointer text-base text-white dark:text-white font-bold;
}
</style>
