import ApiClient from '../../ApiClient';

// FOR CREATING NEW INSTANCE(INBOX)
class UnofficialWhatsapp extends ApiClient {
  constructor() {
    super('channels/unofficial_whatsapp_channel', { accountScoped: true });
  }
}

export default new UnofficialWhatsapp();

