// debounceMixin.js

export default {
    data() {
      return {
        debouncedTimer: null,
      };
    },
    methods: {
      debouncedHandler(callback, delay = 1000) {
        if (this.debouncedTimer) {
          clearTimeout(this.debouncedTimer);
        }
        this.debouncedTimer = setTimeout(callback, delay);
      },
    },
  };
  