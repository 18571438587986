import conversations from './sidebarItems/conversations';
import contacts from './sidebarItems/contacts';
import reports from './sidebarItems/reports';
import campaigns from './sidebarItems/campaigns';
import settings from './sidebarItems/settings';
import notifications from './sidebarItems/notifications';
import primaryMenu from './sidebarItems/primaryMenu';

export const getSidebarItems = (accountId, plan) => {
  if(plan === 'expired'){
    return{
      primaryMenu: primaryMenu(accountId, plan),
      secondaryMenu: [
        settings(accountId, plan),
      ],
    }

  }
  return {
    primaryMenu: primaryMenu(accountId, plan),
    secondaryMenu: [
      conversations(accountId),
      contacts(accountId),
      reports(accountId),
      campaigns(accountId),
      settings(accountId, plan),
      notifications(accountId),
    ],
  }
};
