<template>
    <div>
      <label :for="id" class="block text-sm font-medium text-gray-700" :class="{ error: validationError }">{{ label }}</label>
      <select :id="id" :name="id" v-model="selectedValue" class="mt-1 p-2 w-full border rounded-md" @blur="validateSelect" @change="validateSelect">
        <option v-for="(option, index) in options" :key="index" :value="getValue(option)">{{ getDisplayName(option) | capitalising }}</option>
      </select>
      <span v-if="validationError" :class="{ 'text-red-600': validationError }">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        required: true,
      },
      selectedDuration: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      validationError: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: '',
      },
    },
    data() {
    return {
        selectedValue: '',
    };
  },
  filters: { 
      capitalising: function (v) { 
        return v.charAt(0).toUpperCase() + v.slice(1);
      } 
    },
  computed: {
    getValue() {
      return option => (this.id === 'selectDuration' ? option : option.id);
    },
    getDisplayName() {
      return option => (this.id === 'selectDuration' ? option : (this.selectedDuration === 'annual' ? option.annual_plan : option.monthly_plan));
    },
  },
    methods: {
      validateSelect() {
        // Emit an input event to update the value
        this.$emit('input', this.selectedValue);
        this.$emit('validate', this.selectedValue); // Emit the validate event to the parent
      },
    },
  };
  </script>
  