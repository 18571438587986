/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  getBillingSubscription() {
    return axios.get(`${this.url}billing_subscription`);
  }
  getAccountDetails () {
    return axios.get(`${this.url}super_admin_bank_details`);
  }

  startBillingSubscription(data) {
    return axios.post(`${this.url}start_billing_subscription`, data);
  }
  localBankTransfer(data) {
    return axios.post(`${this.url}local_bank_transfers`, data);
  }
  cancelBillingSubscription(data) {
    return axios.get(`${this.url}cancel_subscription`,data);
  }
  getCurrentCountry() {
    return axios.get(`${this.url}local_bank_transfers/check_current_country`);
  }
  updatePaymentMethod(data) {
    return axios.get(`${this.url}update_card`, data);
  }
  reSendConfirmationEmail() {
    return axios.post(`${this.url}resend_confirmation_email`);
  }
}

export default new AccountAPI();