<template>
  <div>
    <woot-loading-state v-if="isLoading" />
    <div v-else>
      <billing-item
        v-if="isSubscriptionSchedualed"
        :title="getScheduledTitle"
        :description="getScheduledDescription"
      />
      <billing-item
        v-if="!isLoading"
        :title="getTitle"
        :description="getDescription"
        type="upgrade"
        :actionButtonText="actionButtonText"
      />
      <billing-item
        v-if="itemAllowed && plansAvailable"
        :title="$t('BILLING_SETTINGS.BILLING_ITEMS.DOWNGRADE.TITLE')"
        type="downgrade"
        :description="
          $t('BILLING_SETTINGS.BILLING_ITEMS.DOWNGRADE.DESCRIPTION')
        "
        :actionButtonText="
          $t('BILLING_SETTINGS.BILLING_ITEMS.DOWNGRADE.BUTTON_TEXT')
        "
      />
      <billing-item
        v-if="itemAllowed"
        :title="$t('BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SUBSCRIPTION.TITLE')"
        type="cancel"
        :description="
          $t('BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SUBSCRIPTION.DESCRIPTION')
        "
        :actionButtonText="
          $t('BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SUBSCRIPTION.BUTTON_TEXT')
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import configMixin from "shared/mixins/configMixin";
import accountMixin from "../../../../../mixins/account";
import BillingItem from "./BillingItem.vue";
export default {
  mixins: [accountMixin, alertMixin, configMixin],
  components: { BillingItem },
  validations: {},
  computed: {
    ...mapGetters({
      globalConfig: "globalConfig/get",
      accountId: "getCurrentAccountId",
      getAccount: "subscription/getAccount",
      subscription: "subscription/getSubscription",
      subscriptionStatus: "subscription/getSubscriptionStatus",
      uiFlags: "subscription/getUIFlags",
    }),
    isLoading() {
      return this.uiFlags.isFetchingItem || this.uiFlags.isFetching;
    },
    itemAllowed() {
      return !(
        !this.subscription.plan_name ||
        this.getFormatedName(this.subscription.plan_name) === "free" ||
        this.subscription.plan_name === "expired" ||
        this.subscription.subscription_cancellation_status
      );
    },
    plansAvailable() {
      return this.getPlans("downgrade")?.length;
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },
    actionButtonText() {
      return !this.subscription.plan_name
        ? ""
        : this.$t("BILLING_SETTINGS.BILLING_ITEMS.UPGRADE.BUTTON_TEXT");
    },
    getScheduledTitle() {
      return this.$t("BILLING_SETTINGS.BILLING_ITEMS.SCHEDULED_PLAN.TITLE", {
        plan: `${this.subscription.scheduled_interval} ${this.subscription.scheduled_plan}`,
      });
    },
    getScheduledDescription() {
      if (this.subscription.scheduled_plan) {
        return this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.SCHEDULED_PLAN.DESCRIPTION",
          { date: this.getDateTime(this.subscription.subscription_end_time) }
        );
      }
    },
    getDescription() {
      const { plan_name = "", subscription_cancellation_status = false, plan_interval = '' } =
        this.subscription;

      if (!plan_name) {
        return this.$t("BILLING_SETTINGS.FORM.CURRENT_PLAN.NO_PLAN");
      }

      if (plan_name === "expired") {
        return this.$t("BILLING_SETTINGS.FORM.CURRENT_PLAN.PLAN_EXPIRED");
      }

      if (
        plan_name &&
        plan_name !== "expired" &&
        !subscription_cancellation_status
      ) {
        const formattedName = this.getFormatedName(plan_name);
        const descriptionKey =
          formattedName === "free"
            ? "UPGRADE.DESCRIPTION_FOR_FREE_PLAN" :
            !this.getPlans("upgrade")?.length ? 'HIGHEST_PLAN.DESCRIPTION' : "UPGRADE.DESCRIPTION";
        return this.$t(`BILLING_SETTINGS.BILLING_ITEMS.${descriptionKey}`, {
          plan: plan_name,
          currentInterval: plan_interval
        });
      }

      if (plan_name && subscription_cancellation_status) {
        const cancellationDate = this.getDateTime(
          this.subscription.subscription_cancellation_date
        );
        return this.$t(
          "BILLING_SETTINGS.BILLING_ITEMS.CANCEL_SCHEDULED.TITLE",
          { currentInterval: plan_interval, plan: plan_name, date: cancellationDate }
        );
      }
    },
    getTitle() {
      if (!this.subscription.plan_name) {
        return "";
      }
      return this.$t("BILLING_SETTINGS.BILLING_ITEMS.UPGRADE.TITLE");
    },
    isSubscriptionSchedualed() {
      return this.subscription.scheduled_plan || false;
    },
  },
  mounted() {
    this.initializeAccountBillingSubscription();
  },
  methods: {
    getDateTime(dateTime) {
      return new Date(dateTime).toDateString();
    },
  },
};
</script>

<style lang="scss">
@import "~dashboard/assets/scss/variables.scss";
@import "~dashboard/assets/scss/mixins.scss";
.profile--settings {
  padding: 24px;
  overflow: auto;
}
.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}
</style>
