<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] h-auto">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
      :header-content="
        useInstallationName(
          $t('INBOX_MGMT.ADD.AUTH.DESC'),
          'Gueswi'
        )
      "
    />
    <woot-loading-state v-if="uiFlags.isFetchingItem" />
    <div v-else-if="!subscription.plan_name && !uiFlags.isFetchingItem" class="text-center mt-20">{{
      $t("BILLING_SETTINGS.FORM.CURRENT_PLAN.NO_PLAN")
    }}</div>
     <div v-else-if="subscription.plan_name === 'expired' && !uiFlags.isFetchingItem" class="text-center mt-20">{{
      $t("BILLING_SETTINGS.FORM.CURRENT_PLAN.PLAN_EXPIRED")
    }}</div>
    <div v-else class="mt-6 mx-0 flex flex-wrap">
      <channel-item
        v-for="channel in channelList"
        :key="channel.key"
        :channel="channel"
        :enabled-features="enabledFeatures"
        @channel-item-click="initChannelAuth"
      />
    </div>
  </div>
</template>

<script>
import ChannelItem from 'dashboard/components/widgets/ChannelItem.vue';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader.vue';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import accountMixin from "../../../../mixins/account";

export default {
  components: {
    ChannelItem,
    PageHeader,
  },
  mixins: [globalConfigMixin, accountMixin],
  data() {
    return {
      enabledFeatures: {},
        channelListAccordingToPlan:{
          website: { key: 'website', name: 'Website' },
          facebook: { key: 'facebook', name: 'Messenger' },
          whatsapp: { key: 'whatsapp', name: 'WhatsApp' },
          unofficialWhatsapp: { key: 'unofficialWhatsapp', name: 'WhatsApp Lite' },
          telegram: { key: 'telegram', name: 'Telegram' },
          line: { key: 'line', name: 'Line' },
          sms: { key: 'sms', name: 'SMS' },
          email: { key: 'email', name: 'Email' }
          // {
          //   key: 'api',
          //   name: apiChannelName || 'API',
          //   thumbnail: apiChannelThumbnail,
          // }
      }
    };
  },
  computed: {
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
    channelList() {
      // const { apiChannelName, apiChannelThumbnail } = this.globalConfig;
      if(this.account.channels.length) {
        const channels = this.account.channels.map(
          (name) => this.channelListAccordingToPlan[name]
        );
        return channels;
      }
      return [];
    },
    ...mapGetters({
      accountId: "getCurrentAccountId",
      globalConfig: "globalConfig/get",
      subscription: "subscription/getSubscription",
      uiFlags: "subscription/getUIFlags",
    }),
  },
  mounted() {
    this.initializeEnabledFeatures();
    this.initializeAccountBillingSubscription();
  },
  methods: {
    async initializeEnabledFeatures() {
      this.enabledFeatures = this.account.features;
    },
    initChannelAuth(channel) {
      const params = {
        page: "new",
        sub_page: channel,
      };
      router.push({ name: "settings_inboxes_page_channel", params });
    },
  },
};
</script>
<style scoped>
.height-auto {
  height: auto;
}

.channel-list {
  margin-top: var(--space-medium);
}
</style>
