export const getters = {
  getContacts($state) {
    const contacts = $state.sortOrder.map((contactId) => {
      let contact = $state.records[contactId]
      contact.name ===  'Gueswi' ? contact.thumbnail = '/brand-assets/logo_thumbnail.svg' : contact.thumbnail;
      return contact;
    })
    return contacts;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContact: $state => id => {
    const contact = $state.records[id];
    contact.name ===  'Gueswi' ? contact.thumbnail = '/brand-assets/logo_thumbnail.svg' : contact.thumbnail;
    return contact || {};
  },
  getMeta: $state => {
    return $state.meta;
  },
  getAppliedContactFilters: _state => {
    return _state.appliedFilters;
  },
};
