import VueRouter from "vue-router";

import { frontendURL } from "../helper/URLHelper";
import dashboard from "./dashboard/dashboard.routes";
import store from "../store";
import { validateLoggedInRoutes } from "../helper/routeHelpers";
import AnalyticsHelper from "../helper/AnalyticsHelper";

const routes = [...dashboard.routes];

window.roleWiseRoutes = {
  agent: [],
  administrator: [],
};

// generateRoleWiseRoute - updates window object with agent/admin route
const generateRoleWiseRoute = (route) => {
  route.forEach((element) => {
    if (element.children) {
      generateRoleWiseRoute(element.children);
    }
    if (element.roles) {
      element.roles.forEach((roleEl) => {
        window.roleWiseRoutes[roleEl].push(element.name);
      });
    }
  });
};
// Create a object of routes
// accessible by each role.
// returns an object with roles as keys and routeArr as values
generateRoleWiseRoute(routes);

export const router = new VueRouter({ mode: "history", routes });

export const validateAuthenticateRoutePermission = (to, next, { getters }) => {
  const { isLoggedIn, getCurrentUser: user } = getters;

  if (!isLoggedIn) {
    window.location = "/app/login";
    return "/app/login";
  }

  const { plan_name = "" } = getters["subscription/getSubscription"];
  if (plan_name === "expired" && to.name !== "billing_settings_index") {
    const params = { accountId: user.account_id };
    return next({ name: "billing_settings_index", params: params });
  }

  if (!to.name) {
    return next(frontendURL(`accounts/${user.account_id}/dashboard`));
  }

  const nextRoute = validateLoggedInRoutes(
    to,
    getters.getCurrentUser,
    window.roleWiseRoutes
  );
  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

export const initalizeRouter = () => {
  store.dispatch("subscription/getBillingSubscription");
  const userAuthentication = store.dispatch("setUser");

  router.beforeEach((to, from, next) => {
    AnalyticsHelper.page(to.name || "", {
      path: to.path,
      name: to.name,
    });

    userAuthentication.then(() => {
      return validateAuthenticateRoutePermission(to, next, store);
    });
  });
};

export default router;
