export const INBOX_TYPES = {
  WEB: "Channel::WebWidget",
  FB: "Channel::FacebookPage",
  TWITTER: "Channel::TwitterProfile",
  TWILIO: "Channel::TwilioSms",
  WHATSAPP: "Channel::Whatsapp",
  UNOFFICIALWHATSAPP: "Channel::UnofficialWhatsapp",
  API: "Channel::Api",
  EMAIL: "Channel::Email",
  TELEGRAM: "Channel::Telegram",
  LINE: "Channel::Line",
  SMS: "Channel::Sms",
};

export const INBOX_FEATURES = {
  REPLY_TO: "replyTo",
  REPLY_TO_OUTGOING: "replyToOutgoing",
};

// This is a single source of truth for inbox features
// This is used to check if a feature is available for a particular inbox or not
export const INBOX_FEATURE_MAP = {
  [INBOX_FEATURES.REPLY_TO]: [
    INBOX_TYPES.FB,
    INBOX_TYPES.WEB,
    INBOX_TYPES.TWITTER,
    INBOX_TYPES.WHATSAPP,
    INBOX_TYPES.UNOFFICIALWHATSAPP,
    INBOX_TYPES.TELEGRAM,
    INBOX_TYPES.API,
  ],
  [INBOX_FEATURES.REPLY_TO_OUTGOING]: [
    INBOX_TYPES.WEB,
    INBOX_TYPES.TWITTER,
    INBOX_TYPES.WHATSAPP,
    INBOX_TYPES.UNOFFICIALWHATSAPP,
    INBOX_TYPES.TELEGRAM,
    INBOX_TYPES.API,
  ],
};

export default {
  computed: {
    channelType() {
      return this.inbox.channel_type;
    },
    whatsAppAPIProvider() {
      return this.inbox.provider || "";
    },
    isAPIInbox() {
      return this.channelType === INBOX_TYPES.API;
    },
    isATwitterInbox() {
      return this.channelType === INBOX_TYPES.TWITTER;
    },
    isAFacebookInbox() {
      return this.channelType === INBOX_TYPES.FB;
    },
    isAUnofficialWhatsappInbox() {
      const { medium: medium = "" } = this.inbox;
      return (
        this.channelType === INBOX_TYPES.UNOFFICIALWHATSAPP &&
        medium === "unofficialWhatsapp"
      );
    },
    isAWebWidgetInbox() {
      return this.channelType === INBOX_TYPES.WEB;
    },
    isATwilioChannel() {
      return this.channelType === INBOX_TYPES.TWILIO;
    },
    isALineChannel() {
      return this.channelType === INBOX_TYPES.LINE;
    },
    isAnEmailChannel() {
      return this.channelType === INBOX_TYPES.EMAIL;
    },
    isATelegramChannel() {
      return this.channelType === INBOX_TYPES.TELEGRAM;
    },
    isATwilioSMSChannel() {
      const { medium: medium = "" } = this.inbox;
      return this.isATwilioChannel && medium === "sms";
    },
    isASmsInbox() {
      return this.channelType === INBOX_TYPES.SMS || this.isATwilioSMSChannel;
    },
    isATwilioWhatsAppChannel() {
      const { medium: medium = "" } = this.inbox;
      return this.isATwilioChannel && medium === "whatsapp";
    },
    isAWhatsAppCloudChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP &&
        this.whatsAppAPIProvider === "whatsapp_cloud"
      );
    },
    is360DialogWhatsAppChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP &&
        this.whatsAppAPIProvider === "default"
      );
    },
    chatAdditionalAttributes() {
      const { additional_attributes: additionalAttributes } = this.chat || {};
      return additionalAttributes || {};
    },
    isTwitterInboxTweet() {
      return this.chatAdditionalAttributes.type === "tweet";
    },
    twilioBadge() {
      return `${this.isATwilioSMSChannel ? "sms" : "whatsapp"}`;
    },
    twitterBadge() {
      return `${this.isTwitterInboxTweet ? "twitter-tweet" : "twitter-dm"}`;
    },
    facebookBadge() {
      return this.chatAdditionalAttributes.type || "facebook";
    },
    inboxBadge() {
      let badgeKey = "";
      if (this.isATwitterInbox) {
        badgeKey = this.twitterBadge;
      } else if (this.isAFacebookInbox) {
        badgeKey = this.facebookBadge;
      } else if (this.isATwilioChannel) {
        badgeKey = this.twilioBadge;
      } else if (this.isAWhatsAppChannel) {
        badgeKey = "whatsapp";
      } else if (this.isAUnofficialWhatsappInbox) {
        badgeKey = "unofficialWhatsapp";
      }
      return badgeKey || this.channelType;
    },
    isAWhatsAppChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP ||
        this.isATwilioWhatsAppChannel
      );
    },
  },
  methods: {
    inboxHasFeature(feature) {
      return INBOX_FEATURE_MAP[feature]?.includes(this.channelType) ?? false;
    },
    getTransfomedLabel(label) {
      let name = label;
      if (typeof name === "string" && name?.includes("_acc")) {
        const parts = name.split("_");
        parts.pop();
        const formattedName = parts.join("_");
        return formattedName;
      }
      return name;
    },
    async reConnectInboxCallback() {
      const instanceName = this.inbox.name;
      try {
        const status = await this.getStatus(instanceName);
        if (this.isInboxConnected(status)) {
          this.showAlert(
            this.$t(
              "INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.INBOX_CONNECTION.API.ALREADY_CONNECTED"
            )
          );
          return;
        }
        this.getQrCode(instanceName);
      } catch (error) {
        this.createChannel(
          this.getTransformedLabel(instanceName),
          "123456",
          this.$store.getters.getCurrentAccountId()
        );
      }
    },
    async createChannel(instanceName, number, accountId) {
      try {
        await this.createUnofficialWhatsappChannel(
          instanceName,
          number,
          accountId
        );
        this.showAlert(
          this.$t("INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.QR.API.SUCCESS_MESSAGE")
        );
      } catch (error) {
        this.showAlert(
          this.$t("INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.QR.API.ERROR_MESSAGE")
        );
      }
    },
    async createUnofficialWhatsappChannel(
      instance,
      phone_number,
      currentAccountId
    ) {
      try {
        return await this.$store.dispatch(
          "inboxes/createUnofficialWhatsappChannel",
          {
            instance,
            phone_number,
            account_id: currentAccountId,
            channel: {
              type: "unofficialWhatsapp",
            },
          }
        );
      } catch (error) {
        throw error;
      }
    },
    async getQrCode(instanceName) {
      try {
        const qrCode = await this.$store.dispatch("inboxes/getQrCode", {
          instance_name: instanceName,
        });
        this.showAlert(
          this.$t("INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.QR.API.SUCCESS_MESSAGE")
        );
      } catch (error) {
        this.showAlert(
          this.$t("INBOX_MGMT.ADD.UNOFFICIAL_WHATSAPP.QR.API.ERROR_MESSAGE")
        );
      }
    },
    async getStatus(instanceName) {
      try {
        const status = await this.$store.dispatch(
          "inboxes/inboxConnectionStatus",
          {
            instance_name: instanceName,
          }
        );
        return status;
      } catch (error) {
        throw error;
      }
    },

    async getInboxConnectionStatus() {
      try {
        setTimeout(async () => {
          let status = false;
          try {
            const { name, is_unoff_whatsapp, created_at } = await this.inbox;
            const within30Minutes = is_unoff_whatsapp ? await this.isInboxCreatedWithin30Minutes(created_at) : true
            if (is_unoff_whatsapp && !within30Minutes) {
              status = await this.getStatus(name);
              this.emitToastMessage(this.isInboxDisconnected(status));
            } else {
              this.emitToastMessage(status);
            }
          } catch (error) {
            if (error.response?.status === 404) {
              this.emitToastMessage(true);
            }
          }
        }, 1000);
      } catch (error) {
        console.log("getInboxConnectionStatus-----------------", error)
      }
    },
     async isInboxCreatedWithin30Minutes(createdAtString) {
      try {
        const createdAt = new Date(createdAtString);
        const now = new Date();
        return (
          createdAt.toDateString() === now.toDateString() && 
          now.getTime() - createdAt.getTime() < 30 * 60 * 1000
        );
      } catch (error) {
        console.error("Error in isInboxCreatedWithin30Minutes:", error);
        return false;
      }
    },
    emitToastMessage(isStatusNotOpen) {
      window.bus.$emit(
        isStatusNotOpen ? "newInboxToastMessage" : "onCloseInboxToastMessage"
      );
    },
    isInboxConnected(status) {
      return status?.data?.instance?.instance?.state === "open";
    },
    isInboxDisconnected(status) {
      return status?.data?.instance?.instance?.state !== "open";
    },
  },
};
