import { render, staticRenderFns } from "./OnBoardingNotification.vue?vue&type=template&id=7fda6347&scoped=true&"
import script from "./OnBoardingNotification.vue?vue&type=script&lang=js&"
export * from "./OnBoardingNotification.vue?vue&type=script&lang=js&"
import style0 from "./OnBoardingNotification.vue?vue&type=style&index=0&id=7fda6347&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fda6347",
  null
  
)

export default component.exports